// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$sa-Geel: #e6c42f;
$sa-Koraal: #eb5c34;
$sa-Roze: #c63c8e;
$sa-Paars: #8c5da3;
$sa-Blauw: #4cc0df;
$sa-Green: #0ead81;
$sa-Donkerblauw: #183b4e;

$typography-font-family: 'Sofia_Pro_Regular', Sofia_Pro_Regular !default;

$primary: $sa-Donkerblauw;
$additional: #748993;
$bg: #fafafa;
$grey: #e2e2e2;
$secondary: $sa-Blauw;
$highlight: rgba(76, 192, 223, 0.1);
$accent: #00ac84;
$info: $sa-Paars;
$third: rgba(76, 192, 223, 0.8);
$dark: $sa-Donkerblauw;
$positive: $sa-Green;
$negative: $sa-Koraal;
$warning: #f2c037;

// spacing
$body-font-size: 12px;
$app-border-radius: 100px;
$space-1: 8px;
$space-2: ($space-1 * 2);
$space-3: ($space-1 * 3);
